export interface Club {
  id: number;
  date: Date;
  slug: string;
  type: string;
  link: string;
  title: Title;
  acf: ClubAcf;
  _links: Links;
}

export interface Links {
  self: About[];
  collection: About[];
  about: About[];
  'version-history': VersionHistory[];
  'predecessor-version': PredecessorVersion[];
  'wp:attachment': About[];
  'wp:term': WpTerm[];
  curies: Cury[];
}

export interface About {
  href: string;
}

export interface Cury {
  name: string;
  href: string;
  templated: boolean;
}

export interface PredecessorVersion {
  id: number;
  href: string;
}

export interface VersionHistory {
  count: number;
  href: string;
}

export interface WpTerm {
  taxonomy: string;
  embeddable: boolean;
  href: string;
}

export interface ClubAcf {
  seo: SEO;
  promo_banner_active: boolean;
  promo_banner: PromoBanner;
  cta_active: boolean;
  presale_block_active: boolean;
  bottom_marketing_block_active: boolean;
  bottom_marketing_block: BottomMarketingBlock[];
  facility_plans: FacilityPlans;
  intro: Intro;
  photos: Photo[];
  equipment: Equipment;
  floor_plan: FloorPlan;
  working_hours_active: boolean;
  working_hours: WorkingHours;
  holiday_hours: HolidayHours;
  '360_tour': string;
  cta: Cta;
  presale_block_image: Image;
  show_chat: boolean;
  common_rate_card_settings: CommonRateCardSettings;
  emergency_settings: boolean;
  free_trial: boolean;
  extra_marketing_block_active: boolean;
  extra_marketing_block: ExtraMarketingBlock[];
  default_floorplan_images: DefaultImages;
  default_map_view_images: DefaultImages;
  default_tour_images: DefaultImages;
  footer_plan_content: string;
  map_image: Image;
}

export interface BottomMarketingBlock {
  ID: number;
  post_author: string;
  post_date: Date;
  post_date_gmt: Date;
  post_content: string;
  post_title: string;
  post_excerpt: string;
  post_status: string;
  comment_status: string;
  ping_status: string;
  post_password: string;
  post_name: string;
  to_ping: string;
  pinged: string;
  post_modified: Date;
  post_modified_gmt: Date;
  post_content_filtered: string;
  post_parent: number;
  guid: string;
  menu_order: number;
  post_type: string;
  post_mime_type: string;
  comment_count: string;
  filter: string;
  acf: BottomMarketingBlockAcf;
}

export interface BottomMarketingBlockAcf {
  type: string;
  thumbnail: CoverImageClass;
  video: string;
  link: string;
  link_type: string;
  open_link_on_new_tab: boolean;
}

export interface CoverImageClass {
  desktop: string;
  tablet: string;
  mobile: string;
}

export interface CommonRateCardSettings {
  ID: number;
  post_author: string;
  post_date: Date;
  post_date_gmt: Date;
  post_content: string;
  post_title: string;
  post_excerpt: string;
  post_status: string;
  comment_status: string;
  ping_status: string;
  post_password: string;
  post_name: string;
  to_ping: string;
  pinged: string;
  post_modified: Date;
  post_modified_gmt: Date;
  post_content_filtered: string;
  post_parent: number;
  guid: string;
  menu_order: number;
  post_type: string;
  post_mime_type: string;
  comment_count: string;
  filter: string;
  acf: CommonRateCardSettingsAcf;
}

export interface CommonRateCardSettingsAcf {
  discount_line_text: string;
  discount_line_text_color: string;
  due_today_line_text_color: string;
  promo_code: PromoCode;
}

export interface PromoCode {
  show_promo_code: boolean;
  green: boolean;
  blue: boolean;
  gray: boolean;
  orange: boolean;
  promo_copy: string;
}

export interface Cta {
  ID: number;
  post_author: string;
  post_date: Date;
  post_date_gmt: Date;
  post_content: string;
  post_title: string;
  post_excerpt: string;
  post_status: string;
  comment_status: string;
  ping_status: string;
  post_password: string;
  post_name: string;
  to_ping: string;
  pinged: string;
  post_modified: Date;
  post_modified_gmt: Date;
  post_content_filtered: string;
  post_parent: number;
  guid: string;
  menu_order: number;
  post_type: string;
  post_mime_type: string;
  comment_count: string;
  filter: string;
  acf: CtaAcf;
}

export interface CtaAcf {
  call_to_action_type: string;
  call_to_action_block: string;
  call_to_action_block_2: string;
  call_to_action_block_image: Image;
}

export interface Image {
  desktop: boolean;
  tablet: boolean;
  mobile: boolean;
}

export interface DefaultImages {
  ID: number;
  post_author: string;
  post_date: Date;
  post_date_gmt: Date;
  post_content: string;
  post_title: string;
  post_excerpt: string;
  post_status: string;
  comment_status: string;
  ping_status: string;
  post_password: string;
  post_name: string;
  to_ping: string;
  pinged: string;
  post_modified: Date;
  post_modified_gmt: Date;
  post_content_filtered: string;
  post_parent: number;
  guid: string;
  menu_order: number;
  post_type: string;
  post_mime_type: string;
  comment_count: string;
  filter: string;
  acf: CoverImageClass;
}

export interface Equipment {
  ID: number;
  post_author: string;
  post_date: Date;
  post_date_gmt: Date;
  post_content: string;
  post_title: string;
  post_excerpt: string;
  post_status: string;
  comment_status: string;
  ping_status: string;
  post_password: string;
  post_name: string;
  to_ping: string;
  pinged: string;
  post_modified: Date;
  post_modified_gmt: Date;
  post_content_filtered: string;
  post_parent: number;
  guid: string;
  menu_order: number;
  post_type: string;
  post_mime_type: string;
  comment_count: string;
  filter: string;
  acf: EquipmentAcf;
}

export interface EquipmentAcf {
  title: string;
  text: string;
  equipment_image: string;
  equipment_footer: string;
  order: string;
}

export interface ExtraMarketingBlock {
  ID: number;
  post_author: string;
  post_date: Date;
  post_date_gmt: Date;
  post_content: string;
  post_title: string;
  post_excerpt: string;
  post_status: string;
  comment_status: string;
  ping_status: string;
  post_password: string;
  post_name: string;
  to_ping: string;
  pinged: string;
  post_modified: Date;
  post_modified_gmt: Date;
  post_content_filtered: string;
  post_parent: number;
  guid: string;
  menu_order: number;
  post_type: string;
  post_mime_type: string;
  comment_count: string;
  filter: string;
  acf: ExtraMarketingBlockAcf;
}

export interface ExtraMarketingBlockAcf {
  title: string;
  content: string;
  footer: string;
  order: string;
}

export interface FacilityPlans {
  planPrices?: {
    AgreementPrice: string | number;
    agreementGroupId: number;
    agreementId: number;
    agreementItemId: number;
    agreementMonthly: string | number | any;
    agreementMonthlyTax: string | number | any;
    agreementName: string;
    agreementStartUpFee: string | number;
    agreementStartUpTax: string | number;
  };
  '': null;
  green: Green;
  _copy2: null;
  blue: Blue;
  _copy: null;
  gray: Gray;
  orange: Green;
}

interface Blue {
  is_active: boolean;
  show_details: boolean;
  best_value: boolean;
  plan_title: string;
  color_settings: ColorSettings;
  join_now: JoinNow;
  benefits: BlueBenefits;
  foot_note: FootNote;
}

export interface BlueBenefits {
  ID: number;
  post_author: string;
  post_date: Date;
  post_date_gmt: Date;
  post_content: string;
  post_title: string;
  post_excerpt: string;
  post_status: string;
  comment_status: string;
  ping_status: string;
  post_password: string;
  post_name: string;
  to_ping: string;
  pinged: string;
  post_modified: Date;
  post_modified_gmt: Date;
  post_content_filtered: string;
  post_parent: number;
  guid: string;
  menu_order: number;
  post_type: string;
  post_mime_type: string;
  comment_count: string;
  filter: string;
  acf: PurpleAcf;
}

export interface PurpleAcf {
  plan_benefits: PurplePlanBenefit[];
}

export interface PurplePlanBenefit {
  type: Type;
  benefits_title: string;
  link_type: LinkType;
  media_modal: boolean;
  pdf: boolean;
  benefits_foot_note: any[];
  benefits_style: any[];
  custom_color: string;
  spacer_settings: PurpleSpacerSettings;
}

export enum LinkType {
  None = 'none',
}

export interface PurpleSpacerSettings {
  margin_top: number;
  margin_bottom: number;
}

export enum Type {
  Divider = 'divider',
  HTML = 'html',
}

export interface ColorSettings {
  ID: number;
  post_author: string;
  post_date: Date;
  post_date_gmt: Date;
  post_content: string;
  post_title: string;
  post_excerpt: string;
  post_status: string;
  comment_status: string;
  ping_status: string;
  post_password: string;
  post_name: string;
  to_ping: string;
  pinged: string;
  post_modified: Date;
  post_modified_gmt: Date;
  post_content_filtered: string;
  post_parent: number;
  guid: string;
  menu_order: number;
  post_type: string;
  post_mime_type: string;
  comment_count: string;
  filter: string;
  acf: ColorSettingsAcf;
}

export interface ColorSettingsAcf {
  background_color_for_the_price_block: string;
  background_color_for_the_title_block: string;
  background_color_for_the_see_benefits_block: string;
  best_value_main_color: string;
  best_value_secondary_color: string;
}

export interface FootNote {
  ID: number;
  post_author: string;
  post_date: Date;
  post_date_gmt: Date;
  post_content: string;
  post_title: string;
  post_excerpt: string;
  post_status: string;
  comment_status: string;
  ping_status: string;
  post_password: string;
  post_name: string;
  to_ping: string;
  pinged: string;
  post_modified: Date;
  post_modified_gmt: Date;
  post_content_filtered: string;
  post_parent: number;
  guid: string;
  menu_order: number;
  post_type: string;
  post_mime_type: string;
  comment_count: string;
  filter: string;
  acf: FootNoteAcf;
}

export interface FootNoteAcf {
  foot_note: string;
}

export interface JoinNow {
  ID: number;
  post_author: string;
  post_date: Date;
  post_date_gmt: Date;
  post_content: string;
  post_title: string;
  post_excerpt: string;
  post_status: string;
  comment_status: string;
  ping_status: string;
  post_password: string;
  post_name: string;
  to_ping: string;
  pinged: string;
  post_modified: Date;
  post_modified_gmt: Date;
  post_content_filtered: string;
  post_parent: number;
  guid: string;
  menu_order: number;
  post_type: string;
  post_mime_type: string;
  comment_count: string;
  filter: string;
  acf: JoinNowAcf;
}

export interface JoinNowAcf {
  button_type: string;
  button_text: string;
  button_text_color: string;
  button_color: string;
}

export interface Gray {
  is_active: boolean;
  show_details: boolean;
  best_value: boolean;
  plan_title: string;
  color_settings: ColorSettings;
  join_now: JoinNow;
  benefits: GrayBenefits;
  foot_note: FootNote;
}

export interface GrayBenefits {
  ID: number;
  post_author: string;
  post_date: Date;
  post_date_gmt: Date;
  post_content: string;
  post_title: string;
  post_excerpt: string;
  post_status: string;
  comment_status: string;
  ping_status: string;
  post_password: string;
  post_name: string;
  to_ping: string;
  pinged: string;
  post_modified: Date;
  post_modified_gmt: Date;
  post_content_filtered: string;
  post_parent: number;
  guid: string;
  menu_order: number;
  post_type: string;
  post_mime_type: string;
  comment_count: string;
  filter: string;
  acf: FluffyAcf;
}

export interface FluffyAcf {
  plan_benefits: FluffyPlanBenefit[];
}

export interface FluffyPlanBenefit {
  type: Type;
  benefits_title: string;
  link_type: LinkType;
  media_modal: boolean;
  pdf: boolean;
  benefits_foot_note: any[] | string;
  benefits_style: any[] | string;
  custom_color: string;
  spacer_settings: FluffySpacerSettings;
}

export interface FluffySpacerSettings {
  margin_top: number | string;
  margin_bottom: number | string;
}

interface Green {
  is_active: boolean;
  show_details: boolean;
  best_value: boolean;
  plan_title: string;
  color_settings: boolean;
  join_now: boolean;
  benefits: boolean;
  foot_note: boolean;
}

export interface FloorPlan {
  floor_plan_image: string;
}

export interface HolidayHours {
  ID: number;
  post_author: string;
  post_date: Date;
  post_date_gmt: Date;
  post_content: string;
  post_title: string;
  post_excerpt: string;
  post_status: string;
  comment_status: string;
  ping_status: string;
  post_password: string;
  post_name: string;
  to_ping: string;
  pinged: string;
  post_modified: Date;
  post_modified_gmt: Date;
  post_content_filtered: string;
  post_parent: number;
  guid: string;
  menu_order: number;
  post_type: string;
  post_mime_type: string;
  comment_count: string;
  filter: string;
  acf: HolidayHoursAcf;
}

export interface HolidayHoursAcf {
  content: string;
}

export interface Intro {
  background: string;
  '': null;
  cover_image: CoverImageClass;
  intro_slide_content: IntroSlideContent;
  price_sticker: PriceSticker;
}

export interface IntroSlideContent {
  welcome_title: string;
  welcome_title_color: string;
  welcome_title_size: string;
  club_title: string;
  club_title_color: string;
  club_title_size: string;
  promo_title: string;
  promo_title_color: string;
  promo_title_size: string;
}

export interface PriceSticker {
  sticker_active: boolean;
  sticker_text_top: string;
  sticker_text_bottom: string;
  sticker_background_color: string;
}

export interface Photo {
  photo_title: string;
  nearest_location_image: boolean;
  desktop: string;
  tablet: string;
  mobile: string;
}

export interface PromoBanner {
  banner: Banner;
  linked_to: string;
  banner_url: string;
  sweepstakes: Sweepstakes;
  warning_modal: boolean;
}

export interface Banner {
  ID: number;
  post_author: string;
  post_date: Date;
  post_date_gmt: Date;
  post_content: string;
  post_title: string;
  post_excerpt: string;
  post_status: string;
  comment_status: string;
  ping_status: string;
  post_password: string;
  post_name: string;
  to_ping: string;
  pinged: string;
  post_modified: Date;
  post_modified_gmt: Date;
  post_content_filtered: string;
  post_parent: number;
  guid: string;
  menu_order: number;
  post_type: string;
  post_mime_type: string;
  comment_count: string;
  filter: string;
  acf: BannerAcf;
}

export interface BannerAcf {
  show_banner: boolean;
  banner_color: string;
  banner_image: CoverImageClass;
}

export interface Sweepstakes {
  ID: number;
  post_author: string;
  post_date: Date;
  post_date_gmt: Date;
  post_content: string;
  post_title: string;
  post_excerpt: string;
  post_status: string;
  comment_status: string;
  ping_status: string;
  post_password: string;
  post_name: string;
  to_ping: string;
  pinged: string;
  post_modified: Date;
  post_modified_gmt: Date;
  post_content_filtered: string;
  post_parent: number;
  guid: string;
  menu_order: number;
  post_type: string;
  post_mime_type: string;
  comment_count: string;
  filter: string;
  acf: SweepstakesAcf;
}

export interface SweepstakesAcf {
  modal_title: string;
  submit_title: string;
  show_phone: boolean;
  show_terms: boolean;
  recaptcha: boolean;
}

export interface SEO {
  meta_title: string;
  meta_description: string;
  meta_keywords: string;
}

export interface WorkingHours {
  ID: number;
  post_author: string;
  post_date: Date;
  post_date_gmt: Date;
  post_content: string;
  post_title: string;
  post_excerpt: string;
  post_status: string;
  comment_status: string;
  ping_status: string;
  post_password: string;
  post_name: string;
  to_ping: string;
  pinged: string;
  post_modified: Date;
  post_modified_gmt: Date;
  post_content_filtered: string;
  post_parent: number;
  guid: string;
  menu_order: number;
  post_type: string;
  post_mime_type: string;
  comment_count: string;
  filter: string;
  acf: WorkingHoursAcf;
}

export interface WorkingHoursAcf {
  mon: Fri;
  tue: Fri;
  wed: Fri;
  thu: Fri;
  fri: Fri;
  sat: Fri;
  sun: Fri;
}

export interface Fri {
  closed: boolean;
  from: string;
  to: string;
}

export interface Title {
  rendered: string;
}

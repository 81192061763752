import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '@environment';
import { facilitiesReducer } from './facilities-reducer';
import { facilitiesByRegionReducer } from './facilities-by-region.reducer';
import { facilitiesPlansReducer } from './facilities-plans.reducer';
import { facilitiesByGeoReducer } from './facilities-by-geo.reducer';
import { foldersReducer } from './folders.reducer';
import { termsOfUseReducer } from './terms-of-use.reducer';
import { termsAndConditionsReducer } from './terms-and-conditions.reducer';
import { privacyPoliciesReducer } from './privacy-policies.reducer';
import { AppState } from '../store.types';

export const reducers: ActionReducerMap<AppState> = {
  facilities: facilitiesReducer,
  facilitiesByRegion: facilitiesByRegionReducer,
  facilitiesPlans: facilitiesPlansReducer,
  facilitiesByGeo: facilitiesByGeoReducer,
  folders: foldersReducer,
  termsOfUse: termsOfUseReducer,
  termsAndConditions: termsAndConditionsReducer,
  privacyPolicies: privacyPoliciesReducer,
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production
  ? []
  : [];

import { Injectable } from '@angular/core';

// const noop = (): any => undefined;

export abstract class Logger {
  info: any;

  warn: any;

  error: any;

  table: any;
}

@Injectable()
export class LoggerService implements Logger {
  info: any;

  warn: any;

  error: any;

  table: any;

  /**
   *
   * @param {string} type
   * @param {any} args
   */
  invokeConsoleMethod(): void {}
}

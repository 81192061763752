import { createAction, props } from '@ngrx/store';
import { IFacilityPlan } from '@models';
import {
  FacilityPlansActionTypes,
  IFacilitiesPlansRequest,
} from './facilities-plans.actions.types';

/* Get Facility Plans */
/**
 * Load the facility plans from MoSo using `facility.id` and `agreement.id`
 *
 * @example
 *  this.store.dispatch(
 *    loadFacilityPlans(699, 12345),
 *  );
 */
export const loadFacilityPlans = createAction(
  FacilityPlansActionTypes.request,
  props<IFacilitiesPlansRequest>(),
);
export const loadFacilityPlansSuccess = createAction(
  FacilityPlansActionTypes.requestSuccess,
  props<{
    plans: {
      id: number;
      agreement: { [agreementID: number]: IFacilityPlan[] };
    };
  }>(),
);
export const loadFacilityPlansFailure = createAction(
  FacilityPlansActionTypes.requestFailure,
  props<{ error: any }>(),
);

<div class="container-fluid container-fix min-height-page">
  <div class="bg-accent error-wrapper">
    <h1 class="text-center text-white text-uppercase mt-3 mb-0 pt-5 BlinkFitness_Strong">Surprise!</h1>
    <h3 class="text-center text-white BlinkFitness_Balanced">You’ve found our super-secret, feel-good page.</h3>
    <p class="text-center">If this isn’t where you wanted to go, your best bet is to go back to the home<br>
      page and start again.</p>
    <p class="text-center mt-4">
      If you'd like some assistance getting to the right place, please contact us at<br>
      <a href="mailto:support@blinkfitness.com">support@blinkfitness.com</a>
    </p>
    <div fxLayout='row' fxLayoutAlign='center' class="mt-4 position-relative">
      <a href="{{ redirectDomain }}" class="text-center text-uppercase BlinkFitness_Balanced">
        Take me to the home page
      </a>
    </div>
    <p class="text-center mt-4">While you're here though: an interesting fact about this page is that the<br>
      background color we’ve used here is called “Papaya” and it tends to make<br> people feel energized.</p>
    <p class="text-center mt-4 mb-5 pb-5">You'll see it on the walls of all our gyms. Feel free to hang out here
      and soak<br> up the energy for as long as you want.</p>
  </div>
</div>

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { CoreModule } from '@core/core.module';
import { AuthGuard } from '@core/guards/auth.guard';
import { reducers, metaReducers } from '@store/reducers';
import { FacilitiesEffects } from '@store/effects/facilities.effects';
import { FacilitiesByGeoEffects } from '@store/effects/facilities-by-geo.effects';
import { FacilitiesByRegionEffects } from '@store/effects/facilities-by-region.effects';
import { FacilityPlansEffects } from '@store/effects/facility-plans.effects';
import { TermsOfUseEffects } from '@store/effects/terms-of-use.effects';
import { PrivacyPoliciesEffects } from '@store/effects/privacy-policies.effects';
import { FoldersEffects } from '@store/effects/folders.effects';
import { environment } from '@environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([
      FacilitiesEffects,
      FacilitiesByGeoEffects,
      FacilitiesByRegionEffects,
      FacilityPlansEffects,
      TermsOfUseEffects,
      PrivacyPoliciesEffects,
      FoldersEffects,
    ]),
    NgbModule,
  ],
  providers: [AuthGuard],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { AwsAmplifyService } from '@core/services/aws-amplify.service';
import { mapAgreementPrices } from '@shared/helpers';
import {
  FacilityPlansActionTypes,
  IFacilitiesPlansRequest,
} from '../actions/facilities-plans.actions.types';

@Injectable()
export class FacilityPlansEffects {
  /**
   * Effect Get Facility Plans
   *
   * @description
   * This effect is triggered by Load Facility Plans action.
   * It receives an id from facility `id` and agreement `id`
   */
  getFacilityPlans$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FacilityPlansActionTypes.request),
      mergeMap((action: IFacilitiesPlansRequest) =>
        this.ampService.getCorporatePrices(action.id).pipe(
          map((data) => {
            console.log('data', data);
            if (
              !data ||
              !data.PrimaryMemberAgreementId ||
              data.PrimaryMemberAgreementId === 'No ID'
            ) {
              return {
                type: FacilityPlansActionTypes.requestFailure,
                error: 'No prices',
              };
            }
            const prices = {
              type: FacilityPlansActionTypes.requestSuccess,
              plans: {
                id: action.id,
                agreement: {
                  [data?.PrimaryMemberAgreementId]: mapAgreementPrices(
                    data?.corporateAgreementPrices,
                  ),
                },
              },
            };
            console.log('prices', prices);
            return prices;
          }),
          catchError((error) => {
              console.log('error', error);
              return of({
                type: FacilityPlansActionTypes.requestFailure,
                error,
              })
            }
          ),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private ampService: AwsAmplifyService,
  ) {}
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from '@core/layout/templates/main/main.component';
import { P404Component } from '@public-pages/other/404.component';
import { SimpleComponent } from '@core/layout/templates/simple/simple.component';
import { AuthGuard } from '@core/guards/auth.guard';
import { UnauthGuard } from '@core/guards/unauth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'public',
    pathMatch: 'full',
  },
  {
    path: 'public',
    component: SimpleComponent,
    canActivate: [UnauthGuard],
    loadChildren: () =>
      import('@public-pages/public-pages.module').then(
        (mod) => mod.PublicPagesModule,
      ),
  },
  {
    path: 'secure',
    component: MainComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () =>
      import('@secure-pages/secure-pages.module').then(
        (mod) => mod.SecurePagesModule,
      ),
  },
  {
    path: '404',
    component: P404Component,
    data: { title: 'Surprise!·We·could not·find·that·page.' },
  },
  {
    path: '**',
    component: P404Component,
    data: { title: 'Surprise!·We·could not·find·that·page.' },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import {
  YextLocations,
  Config,
  YextGeoSearch,
  YextFolders,
} from '@blinkfitness/blink-yext-api';
import {
  IYextLocations,
  IYextLocation,
} from '@blinkfitness/blink-yext-api/dist/lib/models/yextLocations.model';
import { environment } from '@environment';
import { TGeoSearch } from '@store/reducers/facilities-by-geo.reducer.types';
import {
  YextLiveAPI,
  YextKnowledgeAPI,
  defaultFields,
} from './configs/yext.configs';
import { IYextLocationOptions, TRequestService } from './yext.service.types';

/**
 * Yext Service @Injectable()
 */
@Injectable()
export class YextService {
  yextLocations: YextLocations;

  yextGeoSearch: YextGeoSearch;

  yextFolders: YextFolders;

  /**
   * YextService constructor
   * @param httpClient
   */
  constructor(private httpClient: HttpClient) {
    const configLiveAPI: any = Config.newConfig(YextLiveAPI);
    const configKnowledgeAPI: any = Config.newConfig(YextKnowledgeAPI);

    this.yextLocations = new YextLocations(configLiveAPI, this.httpClient);
    this.yextGeoSearch = new YextGeoSearch(configLiveAPI, this.httpClient);
    this.yextFolders = new YextFolders(configKnowledgeAPI, this.httpClient);
  }

  /**
   * Get Yext API Instance with options
   *
   * @param {IYextLocationOptions} options
   *  Object with Yext API options
   */
  requestWithOption(
    options?: IYextLocationOptions,
    service: TRequestService = 'yextLocations',
  ): YextLocations | YextGeoSearch {
    const { fields = defaultFields, radius, limit, filter = {} } =
      options || {};
    let yext = this[service];

    if (yext?.setFields) {
      yext = yext.setFields(fields);
    }

    if (limit > 0) {
      yext = yext.setLimit(limit);
    }

    if (radius > 0) {
      yext = (<YextGeoSearch>yext).setRadius(radius);
    }

    if (yext?.setFilters) {
      yext = yext.setFilters({
        ...filter,
        name: { $eq: 'Blink Fitness' },
      });
    }

    return yext;
  }

  /**
   * Get All Facilities From Yext
   *
   * @param {IYextLocationOptions} options
   *  Object with Yext API options
   *
   * @example
   *  // Using default fields
   *  yextService.getAllFacilities();
   *
   *  // With fields option
   *  yextService.getAllFacilities({ fields: ['mainPhone'] });
   */
  getAllFacilities(options?: IYextLocationOptions): Observable<IYextLocations> {
    const yext = this.requestWithOption(options);
    return from((<YextLocations>yext).getAll());
  }

  /**
   * Get a Facility From Yext
   *
   * @param {string} id
   *  The facility id
   * @param {IYextLocationOptions} options
   *  Object with Yext API options
   *
   * @example
   *  // Gets facility that has id 123, with default fields
   *  yextService.getFacilityById(123);
   *
   *  // Gets facility that has id 123, only 'mainPhone' field
   *  yextService.getFacilityById(123, { fields: ['mainPhone'] });
   */
  getFacilityById(
    id: string,
    options?: IYextLocationOptions,
  ): Observable<IYextLocation> {
    const yext = this.requestWithOption(options);
    // @ts-ignore
    return from((<YextLocations>yext).get(id));
  }

  /**
   * Get a Facility From Yext
   *
   * @param {string | number[]} location
   *  Only entities near this position will be returned.
   *  The values can be specified in one of two ways:
   *    1. Latitude and Longitude (e.g.['40.740957', '-73.987565']).
   *    2. Address (e.g., 'New York', 'NY')
   *
   * @param {IYextLocationOptions} options
   *  Object with Yext API options
   *
   * @example
   *  // Get facilities with address
   *  yextService.getFacilitiesByRegion('New York');
   *
   *  // Get facilities with address, but only 'mainPhone' field
   *  yextService.getFacilitiesByRegion('New York', { fields: ['mainPhone'] });
   */
  getFacilitiesByRegion(
    state: string,
    options?: IYextLocationOptions,
  ): Observable<IYextLocations> {
    const yext = this.requestWithOption({
      ...options,
      filter: {
        ...options?.filter,
        'address.region': state,
      },
    });

    return from((<YextLocations>yext).getAll());
  }

  /**
   * Get a Facility From Yext
   *
   * @param {string | number[]} location
   *  Only entities near this position will be returned.
   *  The values can be specified in one of two ways:
   *    1. Latitude and Longitude (e.g.['40.740957', '-73.987565']).
   *    2. Address (e.g., 'Huntington Park', 'New York')
   *
   * @param {IYextLocationOptions} options
   *  Object with Yext API options
   *
   * @example
   *  // Get facilities with address
   *  yextService.getFacilitiesByCity('Huntington Park');
   *
   *  // Get facilities with address, but only 'mainPhone' field
   *  yextService.getFacilitiesByCity('Huntington Park', { fields: ['mainPhone'] });
   */
  getFacilitiesByCity(
    state: string,
    options?: IYextLocationOptions,
  ): Observable<IYextLocations> {
    const yext = this.requestWithOption({
      ...options,
      filter: {
        ...options?.filter,
        'address.city': state,
      },
    });

    return from((<YextLocations>yext).getAll());
  }

  /**
   * Get a Facility From Yext
   *
   * @param {string | number[]} location
   *  Only entities near this position will be returned.
   *  The values can be specified in one of two ways:
   *    1. Latitude and Longitude (e.g.['40.740957', '-73.987565']).
   *    2. Address (e.g., '1 Madison Ave, New York', 'NY 10010' or 'New York, NY')
   *
   * @param {IYextLocationOptions} options
   *  Object with Yext API options
   *
   * @example
   *  // Get facilities with latitude and longitude
   *  yextService.searchGeoLocation([40.740957, -73.987565]);
   *
   *  // Get facilities with address
   *  yextService.searchGeoLocation('1 Madison Ave, New York');
   *
   *  // Get facilities with address, but only 'mainPhone' field
   *  yextService.searchGeoLocation(123, { fields: ['mainPhone'] });
   */
  searchGeoLocation(
    location: TGeoSearch,
    options?: IYextLocationOptions,
  ): Observable<IYextLocations> {
    const yext = this.requestWithOption(
      options,
      'yextGeoSearch',
    ) as YextGeoSearch;

    if (typeof location === 'string') {
      return from(yext.get(location));
    }

    return from(yext.get(location[0], location[1]));
  }

  /**
   * Get a Folders From Yext
   *
   * @param {IYextLocationOptions} options
   *  Object with Yext API options
   *
   * @example
   *  // Get folders
   *  yextService.getFolders({});
   *
   *  // Get 10 folders tops
   *  yextService.getFolders({ limit: 10 });
   */
  getFolders() {
    const url = `${environment.API_CONF.BLINK_CORP_API}/yext-folders`;
    return this.httpClient.get(url);
  }
}

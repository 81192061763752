import { createReducer, on } from '@ngrx/store';
import * as Actions from '../actions/terms-of-use.actions';
import {
  ITermsOfUseState,
  ITermsOfUseStateData,
} from './terms-of-use.reducer.types';
import { AppState } from '../store.types';
import { request, requestError } from './common';
import { ITermsOfUseAction } from '../actions/terms-of-use.actions.types';

export const initialState: ITermsOfUseState = {
  loading: false,
};

export const termsOfUseReducer = createReducer(
  initialState,
  on(Actions.loadTermsOfUses, request),
  on(
    Actions.loadTermsOfUsesSuccess,
    (
      state: ITermsOfUseState,
      { data }: ITermsOfUseAction,
    ): ITermsOfUseState => ({
      ...state,
      loading: false,
      error: null,
      data: {
        ...state.data,
        ...data,
      },
    }),
  ),
  on(Actions.loadTermsOfUsesFailure, requestError),
);

/**
 * Gets termsOfUse reducer
 * @param {AppState} state
 * @returns {ITermsOfUseState}
 *  The termsOfUse state
 */
export const termsOfUse = (state: AppState): ITermsOfUseState =>
  state.termsOfUse;

/**
 * Gets data from termsOfUse reducer
 * @param {AppState} state
 * @returns {IFacilities}
 *  The termsOfUse state data
 */
export const termsOfUseData = (state: AppState): ITermsOfUseStateData =>
  state.termsOfUse?.data;

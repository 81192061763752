import { Component, OnInit, ViewEncapsulation } from '@angular/core';

/**
 * MainComponent
 * @Component({
 * selector: 'corp-main',
 * templateUrl: './main.component.html',
 * encapsulation: ViewEncapsulation.None
 * })
 */
@Component({
  selector: 'corp-main',
  templateUrl: './main.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class MainComponent implements OnInit {
  /**
   * MainComponent constructor
   */
  constructor() {}

  /**
   * MainComponent ngOnInit
   * @return void
   */
  ngOnInit(): void {}
}

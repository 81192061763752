<div *ngIf="user" class="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 bg-white border-bottom shadow-sm">
  <div class="container">
    <div class="row">
      <h2 class="my-0 mr-md-auto font-weight-normal color-dark-gray">{{user.attributes.name}}</h2>
      <nav class="my-2 my-md-0 mr-md-3">
        <button class="btn btn-outline-secondary" href="'url'" (click)="openInvitationLink()">Invitation Link</button>
      </nav>
      <button class="btn btn-outline-secondary" (click)="signOut()">Log Out</button>
    </div>
  </div>
</div>

/**
 *  Month
 * */
export class Month implements IMonth {
  name: string;

  short: string;

  number: string;

  days: string;

  /**
   * Month constructor
   */
  constructor() {}
}

/**
 *  IMonth
 * */
export interface IMonth {
  name: string;
  short: string;
  number: string;
  days: string;
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { CmsService } from '@core/services/cms.service';
import {
  PrivacyPoliciesActionTypes,
  IPrivacyPoliciesRequest,
} from '../actions/privacy-policies.actions.types';

@Injectable()
export class PrivacyPoliciesEffects {
  /**
   * Effect Get Facilities
   *
   * @description
   * This effect is triggered by Load All Facilities action.
   */
  getPrivacyPolicies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PrivacyPoliciesActionTypes.request),
      mergeMap((action: IPrivacyPoliciesRequest) =>
        this.cmsService.getPrivacy().pipe(
          map((data = []) => ({
            type: PrivacyPoliciesActionTypes.requestSuccess,
            data: {
              [action.id]: {
                pdf: data[0]?.acf?.pdf || '',
                content: data[0]?.content?.rendered || '',
                title: data[0]?.title?.rendered || '',
              },
            },
          })),
          catchError((error) =>
            of({
              type: PrivacyPoliciesActionTypes.requestFailure,
              error,
            }),
          ),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private cmsService: CmsService) {}
}

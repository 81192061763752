import { Component } from '@angular/core';

/**
 * AppComponent
 * @Component({
 * selector: 'app-root',
 * templateUrl: './app.component.html',
 * styleUrls: ['./app.component.scss']
})
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  /**
   * AppComponent constructor
   */
  constructor() {
    console.log('-- app init --');
  }
}

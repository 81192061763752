import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MainComponent } from './templates/main/main.component';
import { FooterComponent } from './navigation/footer/footer.component';
import { HeaderComponent } from './navigation/header/header.component';
import { MenuPrimaryComponent } from './navigation/menu/menu-primary.component';
import { SimpleComponent } from './templates/simple/simple.component';

@NgModule({
  declarations: [
    MainComponent,
    SimpleComponent,
    FooterComponent,
    HeaderComponent,
    MenuPrimaryComponent,
  ],
  exports: [FooterComponent, HeaderComponent],
  imports: [CommonModule, RouterModule, MatSidenavModule, MatButtonModule],
})
export class LayoutModule {}

import * as _ from 'lodash';
import { Subscription } from 'rxjs';

/**
 * Clean All Subscriptions. Call function from ngOnDestroy()
 * cleanSubscriptions(): void
 */
export function cleanSubscriptions(subscriptions: Subscription[]): void {
  subscriptions.forEach(
    (subscription: Subscription) => subscription && subscription.unsubscribe(),
  );
}

export function calculateTax(price: string, taxRite: number): string {
  const tax = Number(price) * taxRite;
  return tax.toFixed(2);
}

export function grandTotal(price: string, tax: number): string {
  const total = Number(price) + Number(tax);
  return total.toFixed(2);
}

/**
 * getCardType(number): string
 * checking credit card numbers and returning creadit card name
 * @param cnumber
 * @returns {string}
 */
export function getCardType(cnumber: string): string {
  // visa
  let re = new RegExp('^4');
  if (cnumber.match(re) !== null) {
    return 'Visa';
  }
  // Mastercard
  if (
    /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
      cnumber,
    )
  ) {
    return 'Mastercard';
  }
  // AMEX
  re = new RegExp('^3[47]');
  if (cnumber.match(re) !== null) {
    return 'AmericanExpress';
  }
  // Discover
  re = new RegExp(
    '^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)',
  );
  if (cnumber.match(re) !== null) {
    return 'Discover';
  }
  return '';
}

/**
 * getDevice(width: number): 'mobile' | 'tablet' | 'desktop'
 * width <= 767 = mobile
 * width > 767 && width <= 1024 = tablet
 * width > 1024 = desktop
 */
export function getDevice(width: number): 'mobile' | 'tablet' | 'desktop' {
  if (width <= 767) {
    return 'mobile';
  }
  if (width > 767 && width <= 1024) {
    return 'tablet';
  }

  return 'desktop';
}

/**
 * getOffsetTop(HTMLElement, number): number
 * Checks element nested offset top
 * @param {HtmlElement | Element} el: HtmlElement | Element
 * @param {string} lastElementClass: number
 * @param {number} accumulator: number
 * @returns {string}
 */
export function getOffsetTop(
  el: HTMLElement,
  lastElementClass?: string,
  accumulator = 0,
): number {
  const offset = accumulator + _.get(el, 'offsetTop', 0);
  const parentEl = el.offsetParent as HTMLElement;
  const isLastElement = parentEl?.className?.includes(lastElementClass);

  return parentEl && !isLastElement
    ? getOffsetTop(parentEl, lastElementClass, offset)
    : offset;
}

import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { YextService } from '@core/services/yext.service';
import { mapYextResponseToReducer } from '@shared/helpers';
import {
  FacilitiesStateActionTypes,
  IFacilitiesByRegionRequest,
  FacilitiesByCityActionTypes,
  IFacilitiesByCityRequest,
} from '../actions/facilities-by-region.actions.types';

/**
 * Facilities by state Effects
 */
@Injectable()
export class FacilitiesByRegionEffects {
  /**
   * Effect Get Facilities by State
   *
   * @description
   * This effect is triggered by Load Facilities by State action.
   * It receives a state from the stateList file
   */
  getFacilitiesByRegion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FacilitiesStateActionTypes.request),
      mergeMap((action: IFacilitiesByRegionRequest) =>
        this.yextService
          .getFacilitiesByRegion(action.state?.abbreviation, action.options)
          .pipe(
            map((data) => ({
              type: FacilitiesStateActionTypes.requestSuccess,
              facilities: {
                [action.state?.abbreviation || '']: mapYextResponseToReducer(
                  data?.response?.entities,
                ),
              },
            })),
            catchError((error) =>
              of({
                type: FacilitiesStateActionTypes.requestFailure,
                error,
              }),
            ),
          ),
      ),
    ),
  );

  /**
   * Effect Get Facilities by State
   *
   * @description
   * This effect is triggered by Load Facilities by State action.
   * It receives a state from the stateList file
   */
  getFacilitiesByCity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FacilitiesByCityActionTypes.request),
      mergeMap((action: IFacilitiesByCityRequest) =>
        this.yextService.getFacilitiesByCity(action.city, action.options).pipe(
          map((data) => ({
            type: FacilitiesByCityActionTypes.requestSuccess,
            facilities: {
              [action.state?.abbreviation || '']: mapYextResponseToReducer(
                data?.response?.entities,
              ),
            },
          })),
          catchError((error) =>
            of({
              type: FacilitiesByCityActionTypes.requestFailure,
              error,
            }),
          ),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private yextService: YextService) {}
}

import {
  IFacilityBenefit,
  IPricingDisclaimer,
  ICorporateAgreementPrices,
  IFacilityPlan,
  EPeriodPlansName,
  EPeriodPlansCommitment,
  TCommitment,
} from '@models';

/**
 * Maps the response from Corporate Prices into an array of Periods
 *
 * @param {ICorporateAgreementPrices} prices
 * @returns {IFacilityPlan[]}
 *  A list of periods to be used by PlansListPeriod component
 */
export function mapAgreementPrices(
  prices?: ICorporateAgreementPrices,
): IFacilityPlan[] {
  const plans: IFacilityPlan[] = [
    { commitment: 'Monthly', plans: [] },
    {
      commitment: 'Annual',
      plans: [],
      text: 'Save money by paying in full!',
    },
  ];
  console.log('=== prices ===', prices);

  for (const plan in prices) {
    if (plan && prices[plan]) {
      console.log('plan', plan);
      const item = prices[plan];
      console.log('item', item);
      const commitment: TCommitment = plan.includes('PIF')
        ? 'Annual'
        : 'Monthly';
      const planIndex = EPeriodPlansName[item.agreementName];
      console.log('planIndex', planIndex);
      const commitmentIndex = EPeriodPlansCommitment[commitment];
      console.log('commitmentIndex', commitmentIndex);

      console.log('plans[commitmentIndex]', plans[commitmentIndex]);

      plans[commitmentIndex].plans[planIndex] = {
        id: item.agreementId,
        groupId: item.agreementGroupId,
        itemId: item.agreementItemId,
        color: item.agreementName?.toLowerCase(),
        name: item.agreementName,
        commitment,
        access: !planIndex ? 'All' : 'Regional',
        price: parseInt(
          item.agreementYearly || item.agreementMonthly || item.AgreementPrice,
          10,
        ),
        promoPrice:
          parseInt(
            item.agreementYearly ||
              item.agreementMonthly ||
              item.AgreementPrice,
            10,
          ) - 5,
        startupTax: parseInt(
          item.agreementStartUpTax || item.agreementYearlyTax?.toString(),
          10,
        ),
        // ToDo: Remove hardcoded value
        discount: 22,
      };
    }
  }
  
  console.log('plans', plans);

  if (plans.every((p) => !p.plans?.length)) {
    return [];
  }

  return plans;
}

/**
 * Maps the available benefits for the plan
 *
 * @param {IFacilityBenefit[]} benefits
 * @param {string} agreementName
 * @returns {string[]}
 *  A list of string with benefist
 */
export function getBenefits(
  benefits?: IFacilityBenefit[],
  agreementName?: string,
): string[] {
  if (!benefits?.length) {
    return [];
  }

  const benefitsList = benefits?.reduce(
    (acc, benefit) =>
      benefit[`show${agreementName}`] ? [...acc, benefit.name] : acc,
    [],
  );

  return benefitsList || [];
}

/**
 * Gets the right pricing disclaimer from the store list
 *
 * @param {IPricingDisclaimer[]} pricingDisclaimer
 * @param {TCommitment} commitment
 * @returns {string}
 *  A string with pricing disclaimer
 */
export function getPricingDisclaimer(
  pricingDisclaimer: IPricingDisclaimer[],
  commitment: TCommitment,
): string {
  if (!pricingDisclaimer?.length) {
    return '';
  }

  if (commitment === 'Monthly' && pricingDisclaimer[2]?.text) {
    return pricingDisclaimer[2]?.text;
  }

  if (commitment === 'Annual' && pricingDisclaimer[3]?.text) {
    return pricingDisclaimer[3]?.text;
  }

  return '';
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { IMonth, IUSAState } from '@models';
import { LoggerService } from './log4ts/logger.service';
import * as SettingsData from '../../const/constants';

@Injectable()
export class SettingsService {
  public readonly statesList$: Observable<IUSAState[]>;

  public readonly monthsList$: Observable<IMonth[]>;

  /** States List */
  private _statesList: BehaviorSubject<IUSAState[]> = new BehaviorSubject(null);

  /** Months List */
  private _monthsList: BehaviorSubject<IMonth[]> = new BehaviorSubject(null);

  /**
   * SettingsService constructor
   * @param {LoggerService} logger
   */
  constructor(private logger: LoggerService) {
    this.statesList$ = this._statesList
      .asObservable()
      .pipe(distinctUntilChanged());

    this.monthsList$ = this._monthsList
      .asObservable()
      .pipe(distinctUntilChanged());

    this.logger.info('-CmsService-');
    this.init();
  }

  /**
   * Getting CMS Settings Data
   * */
  init(): void {
    this._statesList.next(SettingsData.statesList);
    this._monthsList.next(SettingsData.monthsList);
  }
}

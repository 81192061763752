import { Location } from '@blinkfitness/blink-yext-api/dist/lib/models/yextLocations.model';
import { FacilityYext, IDistance } from '@models';

/**
 * Map Yext Location to Reducer interface
 *
 * @param {Location[]} data
 * @returns {Facility[]}
 *  A list of Facilities with type Facility
 */
export function mapYextResponseToReducer(
  data: Location[],
  distances: IDistance[] = [],
): FacilityYext[] {
  return data.map(
    (entity, index) => new FacilityYext(entity, distances[index]),
  );
}

import * as AppConstants from '@core/const/constants';
import { Month } from '@models';

/**
 * Get Current Year
 * @returns {number}
 */
export function getCurrentYear(): number {
  const d = new Date();
  return d.getFullYear();
}

/**
 * Get Years limit (currently = 51)
 * @returns {any[]}
 */
export function getYearsLimit(): any[] {
  const yearsList = [];
  const d = new Date();
  const cy = d.getFullYear();
  for (let i = 0; i < 51; i += 1) {
    yearsList.push(cy + i);
  }
  return yearsList;
}

/**
 *  filterMonth that can be selected year to year
 * @param selectedYear
 */
export function filterMonth(selectedYear: string): Month[] {
  if (selectedYear === getCurrentYear().toString()) {
    const d = new Date();
    const currentMonth = d.getMonth() + 1;
    return AppConstants.monthsList.filter((o: any) => o.number >= currentMonth);
  }
  return AppConstants.monthsList;
}

/**
 * formatMonth(): string
 * format month 2 -> 02
 * */
export function formatMonth(m: number): string | number {
  return m < 10 ? '0' + m : m;
}

/**
 * formatYear(yyyy: any): string
 * format year 2018 -> 18
 * */
export function formatYear(yyyy: string): string {
  return ('' + yyyy).substring(2);
}

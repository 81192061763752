import {
  ValidationErrors,
  AsyncValidator,
  AbstractControl,
} from '@angular/forms';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BriteVerifyService } from '@core/services/brite-verify.service';

/**
 * Brite Verify Validator
 */
@Injectable({ providedIn: 'root' })
export class BriteVerifyValidator implements AsyncValidator {
  constructor(private _briteVerify: BriteVerifyService) {}

  /**
   * Validates email field with Brite Verify service
   *
   * @param {FormControl} control
   * @returns {Observable<{ Briteverify: true }>}
   *  An observable with Brite Verify error
   */
  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    return this._briteVerify.validateEmailBriteverify(control.value).pipe(
      map((res) => (!res?.valid ? { Briteverify: true } : null)),
      catchError(() => of(null)),
    );
  }
}

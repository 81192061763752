import { FormControl } from '@angular/forms';

export function PhoneValidator(control: FormControl) {
  const phone = control.value || '';

  if (phone.replace(/[^0-9]/g, '').length > 10) {
    return {
      PhoneValidator: { value: phone },
    };
  }

  return null;
}

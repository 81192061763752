import { CanActivate, Router } from '@angular/router';

import Auth from '@aws-amplify/auth';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * Unauth Guard Service
 * @Injectable({ providedIn: 'root'})
 */
@Injectable({
  providedIn: 'root',
})
export class UnauthGuard implements CanActivate {
  /**
   * UnauthGuard constructor
   * @param router
   */
  constructor(private router: Router) {}

  /**
   * Check User Unauth Status
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   */
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return Auth.currentAuthenticatedUser()
      .then(
        () =>
          // this.router.navigate(['secure/dashboard']);
          // return false;
          true,
      )
      .catch(() => true);
  }
}

import { Injectable } from '@angular/core';
import { LoggerService } from './log4ts/logger.service';

@Injectable()
export class LocalStorageService {
  constructor(private logger: LoggerService) {
    logger.info('-LocalStorageService-');
  }

  /**
   * getLocalStorage
   * @param key
   * @returns {any | boolean}
   */
  public static getLocalStorage(key): any | boolean {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : false;
  }

  /**
   * setLocaStorage
   * @param name
   * @param data
   */
  public static setLocaStorage(name, data): void {
    localStorage.setItem(name, JSON.stringify(data));
  }

  /**
   * deleteLocaStorage
   * @param name
   */
  public static deleteLocaStorage(name): void {
    localStorage.removeItem(name);
  }

  /**
   * clearLocaStorage
   */
  public static clearLocaStorage(): void {
    localStorage.clear();
  }
}

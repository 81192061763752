// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const API_URL = 'https://qa.api.sls.blinkfitness.com';
export const BLINK_WEBSITE_URL = 'https://qa.web.sls.blinkfitness.com/';
export const environment = {
  production: false,
  isDebugMode: true,
  apiDomain: 'https://qa.api.sls.blinkfitness.com',
  awsConfig: {
    region: 'us-east-1',
    APIregion: 'us-east-1',
    UserPoolId: 'us-east-1_teDUlHS5q',
    UserPoolIdRegion: 'us-east-1',
    ClientId: 'mo2qhr90qnh6cf5rf2lhffk6h',
    storageDomain: '.blinkfitness.com',
  },
  API_CONF: {
    X_API_KEY: 'Jvh55gEKNI67IZwas2sEU2AcSepGOSP48hxfDvad',
    BLINK_CORP_API: 'https://corp-qa.api.sls.blinkfitness.com',
    WEB_MOSO_API: API_URL + '/web-blink.moso-api-qa',
    CMS_API: API_URL + '/web.qa',
    YEXT_KEY: '6c2be8edc5fe1ce984de0da945aa0949',
    RECAPTCHA_KEY: '6Le9qMAUAAAAAJn4yQvKpn0M3v3QmQ1vEnirCHV0',
  },
};

export const AuthConf = {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: environment.awsConfig.UserPoolIdRegion,

    // Required only if it's different from Amazon Cognito Region
    identityPoolRegion: environment.awsConfig.UserPoolIdRegion,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: environment.awsConfig.UserPoolId,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: environment.awsConfig.ClientId,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // // OPTIONAL - Configuration for cookie storage
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: environment.awsConfig.storageDomain,
      // OPTIONAL - Cookie path
      path: '/',
      // OPTIONAL - Cookie expiration in days
      expires: 365,
      // OPTIONAL - Cookie secure flag
      secure: false,
    },
    // authenticationFlowType: 'USER_SRP_AUTH'
  },
  API: {
    endpoints: [],
  },
  aws_appsync_region: environment.awsConfig.APIregion,
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: '',
};

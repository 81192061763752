import { createReducer, on } from '@ngrx/store';
import * as Actions from '../actions/privacy-policies.actions';
import {
  IPrivacyPoliciesState,
  IPrivacyPoliciesStateData,
} from './privacy-policies.reducer.types';
import { AppState } from '../store.types';
import { request, requestError } from './common';
import { IPrivacyPoliciesAction } from '../actions/privacy-policies.actions.types';

export const initialState: IPrivacyPoliciesState = {
  loading: false,
};

export const privacyPoliciesReducer = createReducer(
  initialState,
  on(Actions.loadPrivacyPolicies, request),
  on(
    Actions.loadPrivacyPoliciesSuccess,
    (
      state: IPrivacyPoliciesState,
      { data }: IPrivacyPoliciesAction,
    ): IPrivacyPoliciesState => ({
      ...state,
      loading: false,
      error: null,
      data: {
        ...state.data,
        ...data,
      },
    }),
  ),
  on(Actions.loadPrivacyPoliciesFailure, requestError),
);

/**
 * Gets privacyPolicies reducer
 * @param {AppState} state
 * @returns {IPrivacyPoliciesState}
 *  The privacyPolicies state
 */
export const privacyPolicies = (state: AppState): IPrivacyPoliciesState =>
  state.privacyPolicies;

/**
 * Gets data from privacyPolicies reducer
 * @param {AppState} state
 * @returns {IFacilities}
 *  The privacyPolicies state data
 */
export const privacyPoliciesData = (
  state: AppState,
): IPrivacyPoliciesStateData => state.privacyPolicies?.data;

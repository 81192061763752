import { createReducer, on } from '@ngrx/store';
import * as Actions from '../actions/folders.actions';
import { IFoldersState, IFoldersStateData } from './folders.reducer.types';
import { AppState } from '../store.types';
import { request, requestError } from './common';
import { IFoldersAction } from '../actions/folders.actions.types';

export const initialState: IFoldersState = {
  loading: false,
};

export const foldersReducer = createReducer(
  initialState,
  on(Actions.loadFolders, request),
  on(
    Actions.loadFoldersSuccess,
    (state: IFoldersState, { folders }: IFoldersAction): IFoldersState => ({
      ...state,
      loading: false,
      error: null,
      data: [...(state.data || []), ...folders],
    }),
  ),
  on(Actions.loadFoldersFailure, requestError),
);

/**
 * Gets folders reducer
 * @param {AppState} state
 * @returns {IFoldersState}
 *  The folders state
 */
export const folders = (state: AppState): IFoldersState => state.folders;

/**
 * Gets data from folders reducer
 * @param {AppState} state
 * @returns {IFacilities}
 *  The folders state data
 */
export const foldersData = (state: AppState): IFoldersStateData =>
  state.folders?.data;

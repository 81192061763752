import { on, createReducer } from '@ngrx/store';
import { IFacilitiesPlansAction } from '../actions/facilities-plans.actions.types';
import * as Actions from '../actions/facilities-plans.actions';
import { AppState } from '../store.types';
import { request, requestError } from './common';
import {
  IFacilitiesPlansState,
  IFacilitiesPlansStateDate,
} from './facilities-plans.reducer.types';

/**
 * Initial state for the reducer.
 * That's the value when page loads.
 */
export const initialState: IFacilitiesPlansState = {
  loading: false,
};

/**
 *
 * @param {IFacilitiesPlansState} state
 * @param {IFacilitiesPlansAction} action
 * @returns {IFacilitiesPlansState}
 *  The facilitiesPlans state
 */
const mergeFacilitiesPlans = (
  state: IFacilitiesPlansState,
  { plans }: IFacilitiesPlansAction,
): IFacilitiesPlansState => ({
  ...state,
  loading: false,
  error: null,
  data: {
    ...(state.data || {}),
    [plans.id]: {
      ...((state.data && state.data[plans.id]) || {}),
      ...plans.agreement,
    },
  },
});

/**
 * Facilities Reducer
 */
export const facilitiesPlansReducer = createReducer(
  initialState,
  /* Facilities by State */
  on(Actions.loadFacilityPlans, request),
  on(Actions.loadFacilityPlansSuccess, mergeFacilitiesPlans),
  on(Actions.loadFacilityPlansFailure, requestError),
);

/**
 * Gets facilities plans reducer
 * @param {AppState} state
 * @returns {IFacilitiesPlansState}
 *  The facilities plans state
 */
export const facilitiesPlans = (state: AppState): IFacilitiesPlansState =>
  state.facilitiesPlans;

/**
 * Gets data from facilitiesPlans reducer
 * @param {AppState} state
 * @returns {IFacilitiesPlansStateDate}
 *  The facilitiesPlans state
 */
export const facilitiesPlansData = (
  state: AppState,
): IFacilitiesPlansStateDate => state.facilitiesPlans?.data;

import { ITermsOfUseStateData } from '../reducers/terms-of-use.reducer.types';
import { IBaseAction } from './common.types';

export enum TermsOfUseActionTypes {
  request = '[Terms Of Use] Load',
  requestSuccess = '[Terms Of Use] Load Success',
  requestFailure = '[Terms Of Use] Load Failure',
}

export interface ITermsOfUseAction extends IBaseAction {
  data: ITermsOfUseStateData;
}

export interface ITermsOfUseRequest {
  id: string;
  slug: string;
}

import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { environment } from '@environment';

/**
 * AuthInterceptor
 * @Injectable()
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   * AuthInterceptor constructor
   */
  constructor() {}

  /**
   * intercept
   * @param req
   * @param next
   */
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    /**
     * Set x-api-key and all out coming requests
     */
    const authReq = req.clone({
      headers: req.headers.set('x-api-key', environment.API_CONF.X_API_KEY),
    });

    return next.handle(authReq);
  }
}

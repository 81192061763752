import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root',
})
export class BriteVerifyService {
  constructor(private httpClient: HttpClient) {}

  /**
   * Requests Brite Verify email verification
   * @param {string} email
   * @returns {Observable<any>}
   *  A
   */
  validateEmailBriteverify(email: string): Observable<any> {
    return this.httpClient.post(
      environment.API_CONF.BLINK_CORP_API + '/verifyEmail',
      { email },
    );
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { Observable } from 'rxjs';
import Auth from '@aws-amplify/auth';
import { LoggerService } from '../services/other/log4ts/logger.service';

/**
 * AuthGuard @Injectable()
 */
@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  /**
   * AuthGuard constructor
   * @param router
   * @param logger
   */
  constructor(private router: Router, private logger: LoggerService) {
    this.logger.info('-AuthGuard-');
  }

  /**
   * Check if user canActivate protected Route
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns Observable<boolean> | boolean | Promise<boolean>
   */
  canActivate(): Observable<boolean> | boolean | Promise<boolean> {
    return Auth.currentAuthenticatedUser()
      .then(() => true)
      .catch(() => {
        this.router.navigate(['public/sign-in']);
        return false;
      });
  }

  /**
   * can Activate Child
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   */
  canActivateChild(): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate();
  }
}

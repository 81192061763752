import { Component } from '@angular/core';

@Component({
  selector: 'corp-404',
  templateUrl: '404.component.html',
})
export class P404Component {
  redirectDomain = '';

  constructor() {}
}

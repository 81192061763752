import { createAction, props } from '@ngrx/store';
import {
  IPrivacyPoliciesRequest,
  PrivacyPoliciesActionTypes,
} from './privacy-policies.actions.types';
import { IPrivacyPoliciesStateData } from '../reducers/privacy-policies.reducer.types';

/* Get Privacy Policies */
/**
 * Load the privacy policy from CMS using `id` and `slug`
 *
 * @example
 *  this.store.dispatch(
 *    loadPrivacyPolicies({ id: '699', slug: 'some privacy slug' }),
 *  );
 */
export const loadPrivacyPolicies = createAction(
  PrivacyPoliciesActionTypes.request,
  props<IPrivacyPoliciesRequest>(),
);
export const loadPrivacyPoliciesSuccess = createAction(
  PrivacyPoliciesActionTypes.requestSuccess,
  props<{ data: IPrivacyPoliciesStateData }>(),
);
export const loadPrivacyPoliciesFailure = createAction(
  PrivacyPoliciesActionTypes.requestFailure,
  props<{ error: any }>(),
);

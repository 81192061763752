import { Component } from '@angular/core';

/**
 * Header Component
 */
@Component({
  selector: 'corp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {}

import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { YextService } from '@core/services/yext.service';
import { IYextFolders } from '@core/services/yext.service.types';
import {
  FoldersActionTypes,
  IFoldersRequest,
} from '../actions/folders.actions.types';

/**
 * Folders Effects
 */
@Injectable()
export class FoldersEffects {
  /**
   * Effect Get Folders
   *
   * @description
   * This effect is triggered by Load Folders action.
   */
  getFolders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FoldersActionTypes.request),
      mergeMap((action: IFoldersRequest) =>
        this.yextService.getFolders().pipe(
          map((data: IYextFolders) => ({
            type: FoldersActionTypes.requestSuccess,
            folders: data?.response?.folders,
          })),
          catchError((error) =>
            of({
              type: FoldersActionTypes.requestSuccess,
              error,
            }),
          ),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private yextService: YextService) {}
}

import { Component } from '@angular/core';

/**
 * Simple Component
 * It wraps the main route outlet
 */
@Component({
  selector: 'corp-simple',
  templateUrl: './simple.component.html',
  styleUrls: ['./simple.component.scss'],
})
export class SimpleComponent {}

import { createAction, props } from '@ngrx/store';
import {
  ITermsOfUseRequest,
  TermsOfUseActionTypes,
} from './terms-of-use.actions.types';
import { ITermsOfUseStateData } from '../reducers/terms-of-use.reducer.types';

/* Get Terms of Use */
/**
 * Load the terms of use from CMS using `id` and `slug`
 *
 * @example
 *  this.store.dispatch(
 *    loadTermsOfUses({ id: '699', slug: 'some-terms-slug }),
 *  );
 */
export const loadTermsOfUses = createAction(
  TermsOfUseActionTypes.request,
  props<ITermsOfUseRequest>(),
);
export const loadTermsOfUsesSuccess = createAction(
  TermsOfUseActionTypes.requestSuccess,
  props<{ data: ITermsOfUseStateData }>(),
);
export const loadTermsOfUsesFailure = createAction(
  TermsOfUseActionTypes.requestFailure,
  props<{ error: any }>(),
);

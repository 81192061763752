import { createAction, props } from '@ngrx/store';
import {
  ITermsAndConditionsRequest,
  TermsAndConditionsActionTypes,
} from './terms-and-conditions.actions.types';
import { ITermsAndConditionsStateData } from '../reducers/terms-and-conditions.reducer.types';

/* Get Terms of Use */
/**
 * Load the terms and conditions from CMS using `id` and `slug`
 *
 * @example
 *  this.store.dispatch(
 *    loadTermsAndConditions({ id: '699', slug: 'some-terms-slug }),
 *  );
 */
export const loadTermsAndConditions = createAction(
  TermsAndConditionsActionTypes.request,
  props<ITermsAndConditionsRequest>(),
);
export const loadTermsAndConditionsSuccess = createAction(
  TermsAndConditionsActionTypes.requestSuccess,
  props<{ data: ITermsAndConditionsStateData }>(),
);
export const loadTermsAndConditionsFailure = createAction(
  TermsAndConditionsActionTypes.requestFailure,
  props<{ error: any }>(),
);

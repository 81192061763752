import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Logger } from './logger.service';

export const { isDebugMode } = environment;

const noop = (): any => undefined;

@Injectable()
export class ConsoleLoggerService implements Logger {
  get info() {
    if (isDebugMode) {
      // tslint:disable-next-line:no-console
      return console.info.bind(console);
    }
    return noop;
  }

  get warn() {
    if (isDebugMode) {
      return console.warn.bind(console);
    }
    return noop;
  }

  get error() {
    if (isDebugMode) {
      return console.error.bind(console);
    }
    return noop;
  }

  get table() {
    if (isDebugMode) {
      return console.table.bind(console);
    }
    return noop;
  }

  invokeConsoleMethod(type: string, args?: any): void {
    // tslint:disable-next-line:ban-types
    const logFn = console[type] || console.log || noop;
    logFn.apply(console, [args]);
  }
}

import { IFacility } from '@models';
import { IBaseAction, IRequestYextOptions } from './common.types';
import { TGeoSearch } from '../reducers/facilities-by-geo.reducer.types';

export enum FacilitiesGeoSearchActionTypes {
  request = '[Facilities] Load Facilities By Geo Search',
  requestSuccess = '[Facilities] Load Facilities By Geo Search Success',
  requestFailure = '[Facilities] Load Facilities By Geo Search Failure',
}

export interface IFacilitiesByGeoAction extends IBaseAction {
  facilities: IFacility[];
  search: TGeoSearch;
}

export interface IFacilitiesByGeoSearchRequest extends IRequestYextOptions {
  location: TGeoSearch;
}

import { Component, OnInit } from '@angular/core';

import { AwsAmplifyService } from '../../../services/aws-amplify.service';

/**
 * MenuPrimaryComponent
 * @Component({
 * selector: 'corp-menu-primary',
 * templateUrl: './menu-primary.component.html'
 * })
 */
@Component({
  selector: 'corp-menu-primary',
  templateUrl: './menu-primary.component.html',
  styles: ['.color-dark-gray { color: #444 }'],
})
export class MenuPrimaryComponent implements OnInit {
  user = null;

  /**
   * MenuPrimaryComponent constructor
   * @param awsAmplifyService
   */
  constructor(private awsAmplifyService: AwsAmplifyService) {}

  /**
   * MenuPrimaryComponent ngOnInit
   */
  ngOnInit(): void {
    this.awsAmplifyService
      .getCurrentUserData()
      .then((user) => {
        this.user = user;
        console.log('user->', user);
      })
      .catch((error) => console.error(error));
  }

  openInvitationLink() {
    const { origin } = window.location;
    const url = `${origin}/public/purchase/${this.user.username}`;
    window.open(url, '_blank');
  }

  /**
   * Sign Out
   */
  signOut(): void {
    this.awsAmplifyService.signOut();
  }
}

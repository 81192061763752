import { IFacilityPlan } from '@models';
import { IBaseAction } from './common.types';

export enum FacilityPlansActionTypes {
  request = '[Facilities] Load Facility Plans',
  requestSuccess = '[Facilities] Load Facility Plans Success',
  requestFailure = '[Facilities] Load Facility Plans Failure',
}

export interface IFacilitiesPlansAction extends IBaseAction {
  plans: {
    id: number;
    agreement: { [agreementID: number]: IFacilityPlan[] };
  };
}

export interface IFacilitiesPlansRequest {
  id: string;
  agreement: number;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AmplifyService } from 'aws-amplify-angular';
import { P404Component } from '@public-pages/other/404.component';
import { P500Component } from '@public-pages/other/500.component';
import { BriteVerifyValidator } from '@shared/validators';
import { LayoutModule } from './layout/layout.module';
import { AuthInterceptor } from './services/core/auth-interceptor';
import { LocalStorageService } from './services/other/local-storage.service';
import { LoggerService } from './services/other/log4ts/logger.service';
import { ConsoleLoggerService } from './services/other/log4ts/console-logger.service';
import { AwsAmplifyService } from './services/aws-amplify.service';
import { CmsService } from './services/cms.service';
import { YextService } from './services/yext.service';
import { SettingsService } from './services/other/settings.service';
import { BriteVerifyService } from './services/brite-verify.service';

@NgModule({
  declarations: [P404Component, P500Component],
  imports: [CommonModule, LayoutModule, HttpClientModule],
  exports: [P404Component, P500Component],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: LoggerService, useClass: ConsoleLoggerService },
    LocalStorageService,
    AmplifyService,
    AwsAmplifyService,
    CmsService,
    YextService,
    SettingsService,
    BriteVerifyService,
    BriteVerifyValidator,
  ],
})
export class CoreModule {}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environment';

/**
 * CMS Service @Injectable()
 */
@Injectable()
export class CmsService {
  /**
   * CmsService constructor
   * @param httpClient
   */
  constructor(private httpClient: HttpClient) {}

  /**
   * Get data from CMS pages by its slug
   * @param {string} slug
   * @returns {Observable<any>}
   *  Observable Stream with page data
   */
  getPageBySlugNameGlobal(slug: string): Observable<any> {
    if (!slug) {
      return;
    }

    return this.httpClient.get(
      `${environment.API_CONF.CMS_API}/global/pages/slug/${slug}`,
    );
  }

  /**
   * Get Term And Conditions from CMS by its slug.
   * The slug is found on Yext club data;
   *
   * @param {string} slug
   * @returns {Observable<any>}
   *  Observable Stream with page data
   */
  getTermsAndConditionsBySlug(slug: string): Observable<any> {
    if (!slug) {
      return;
    }

    return this.httpClient.get(
      `${environment.API_CONF.CMS_API}/global/club-terms`,
      { params: { slug } },
    );
  }

  /**
   * Get Privacy policy from CMS by its slug.
   * The slug is found on Yext club data;
   *
   * @returns {Observable<any>}
   *  Observable Stream with page data
   */
  getPrivacy(): Observable<any> {
    return this.httpClient.get(
      `${environment.API_CONF.CMS_API}/global/pages/slug/privacy-policy`,
    );
  }

  /**
   * Get Terms of use from CMS.
   *
   * @returns {Observable<any>}
   *  Observable Stream with page data
   */
  getTermsOfUse(): Observable<any> {
    return this.httpClient.get(
      `${environment.API_CONF.CMS_API}/global/pages/slug/terms`,
    );
  }

  /**
   * Get Club data from Global CMS
   * @param {{ type: string, id: number }} req
   * @returns {Observable<any>}
   *  Observable Stream with requested data
   */
  getGlobalCMSDataByTypeAndId(req: {
    type: string;
    id: number;
  }): Observable<any> {
    if (!req?.id) {
      return;
    }

    return this.httpClient.get(
      `${environment.API_CONF.CMS_API}/global/${req.type}/${req.id}`,
    );
  }

  /**
   * Get Term of use, Privacy policy, PT Terms and Conditions, and others
   * from CMS by its slug.
   * The slug is found on Yext club data;
   *
   * @param {string} slug
   * @returns {Observable<any>}
   *  Observable Stream with page data
   */
  getTermsBySlug(slug: string): Observable<any> {
    if (!slug) {
      return;
    }

    return this.httpClient.get(
      `${environment.API_CONF.CMS_API}/global/club-terms`,
      { params: { slug } },
    );
  }
}

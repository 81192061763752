import { Component, OnInit } from '@angular/core';

/**
 * FooterComponent
 * @Component({
 * selector: 'corp-footer',
 * templateUrl: './footer.component.html'
 * })
 */
@Component({
  selector: 'corp-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  /**
   * FooterComponent constructor
   */
  constructor() {}

  /**
   * FooterComponent ngOnInit
   * @return {void}
   */
  ngOnInit(): void {}

  /**
   * showCorporateModal
   * @param e
   */
  showCorporateModal() {}
}

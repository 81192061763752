import { IPrivacyPoliciesStateData } from '../reducers/privacy-policies.reducer.types';
import { IBaseAction } from './common.types';

export enum PrivacyPoliciesActionTypes {
  request = '[Privacy Policies] Load',
  requestSuccess = '[Privacy Policies] Load Success',
  requestFailure = '[Privacy Policies] Load Failure',
}

export interface IPrivacyPoliciesAction extends IBaseAction {
  data: IPrivacyPoliciesStateData;
}

export interface IPrivacyPoliciesRequest {
  id: string;
}

/**
 * Common Request action,
 * @description
 *  It sets the loading flag to `true` upon a backend request
 *
 * @param state
 * @returns {any}
 *  The state with loading true
 */
export const request = (state: any) => ({ ...state, loading: true });

/**
 * Common Request Error action,
 * @description
 *  It sets the loading flag to `false` and sets the error flag upon a backend request failure
 *
 * @param state
 * @param action
 * @returns {any}
 *  The state with loading false and error
 */
export const requestError = (state: any, { error }: any) => {
  console.error(error);

  return {
    ...state,
    loading: false,
    error,
  };
};

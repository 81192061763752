import { FormControl, FormGroup } from '@angular/forms';

/**
 * Validation all form controls
 * @param {FormGroup} formGroup
 */
export function validateAllFormFields(formGroup: FormGroup): void {
  Object.keys(formGroup.controls).forEach((field) => {
    const control = formGroup.get(field);
    if (control instanceof FormControl) {
      control.markAsTouched({ onlySelf: true });
      control.markAsDirty({ onlySelf: true });
    } else if (control instanceof FormGroup) {
      validateAllFormFields(control);
    }
  });
}

/**
 * unValidate All Form controls
 * @param {FormGroup} formGroup
 */
export function unValidateAllFormFields(formGroup: FormGroup): void {
  Object.keys(formGroup.controls).forEach((field) => {
    const control = formGroup.get(field);
    if (control instanceof FormControl) {
      control.markAsUntouched({ onlySelf: true });
    } else if (control instanceof FormGroup) {
      unValidateAllFormFields(control);
    }
  });
}

/**
 * Reset inputs
 * @param {FormGroup} formGroup
 */
export function resetInputs(formGroup: FormGroup): void {
  formGroup.reset();
}

/**
 * Checks the fields  of a FormGroup for errors
 *
 * @param {FormGroup} form
 *  The FormGroup instance
 * @param {string} control
 *  The FormControl name
 * @param {string} error
 *  The name of the error defined on validator
 * @returns {boolean}
 *  A boolean for field error
 */
export function hasError(
  form: FormGroup,
  control: string,
  error: string,
): boolean {
  return form.controls[control]?.hasError(error);
}

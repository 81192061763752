import * as _ from 'lodash';
import { IFacilitiesStates } from '@models';

/**
 * Performs a deep merge of `source` into `target`.
 * Mutates `target` only but not its objects and arrays.
 *
 * @author inspired by [jhildenbiddle](https://stackoverflow.com/a/48218209).
 */
export function deepMergeObj(_target: any, source: any): any {
  const isObject = (obj: any) => obj && typeof obj === 'object';
  const target = _target;

  if (!isObject(target) || !isObject(source)) {
    return source;
  }

  Object.keys(source).forEach((key) => {
    const targetValue = target[key];
    const sourceValue = source[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      target[key] = targetValue.concat(sourceValue);
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = deepMergeObj({ ...targetValue }, sourceValue);
    } else {
      target[key] = sourceValue;
    }
  });

  return target;
}

/**
 * Creates an object with arrays coming from loadFacilitiesByRegion action
 * @param {IFacilitiesStates} facilities
 */
export function getKeys(facilities: IFacilitiesStates): IFacilitiesStates {
  let obj = {};

  for (const key in facilities) {
    if (key && facilities && facilities[key]) {
      obj = {
        ...obj,
        ..._.keyBy(facilities[key], 'id'),
      };
    }
  }

  return obj;
}

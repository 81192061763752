import {
  ApiResponse,
  CompanyData,
  CompanyMember,
  CreateAgreement,
  CreateAgreementResponse,
  IPrices,
} from '@models';
import { CognitoUser, ISignUpResult } from 'amazon-cognito-identity-js';
import { delay, retry } from 'rxjs/operators';

import { AmplifyService } from 'aws-amplify-angular';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Logger } from 'aws-amplify';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '@environment';

@Injectable()
export class AwsAmplifyService {
  public logger = new Logger('AwsService', 'INFO');

  constructor(
    private amplifyService: AmplifyService,
    private httpClient: HttpClient,
    private router: Router,
  ) {
    this.logger.log('-- AwsAmplifyService init --');
  }

  /**
   * Cognito Sign Up Method
   * @param registerData
   * @returns Promise ISignUpResult
   */
  signUpP(registerData): Promise<ISignUpResult> {
    return this.amplifyService.auth().signUp(registerData);
  }

  /**
   * Cognito Sign In Method
   * @param signInData
   * @returns Promise CognitoUser
   */
  signInP(signInData): Promise<CognitoUser> {
    return this.amplifyService
      .auth()
      .signIn(signInData.email, signInData.password);
  }

  /**
   * Cognito Sign Out Method
   * @returns void
   */
  signOut(): void {
    this.amplifyService
      .auth()
      .signOut()
      .then(() => {
        this.router.navigate(['public/sign-in']);
      })
      .catch((error) => console.log(error));
  }

  /**
   * Cognito Get Current User Info Data
   * @returns Promise Cognito User currentUserInfo
   */
  getCurrentUserData(): Promise<any> {
    return this.amplifyService.auth().currentUserInfo();
  }

  /**
   * Verify MoSo Organization By Id Method
   * @param id
   * @returns Observable Verification Status
   */
  verifyMoSoOrganizationById(id): Observable<any> {
    return this.httpClient.get(
      `${environment.API_CONF.BLINK_CORP_API}/verifyMoSoOrganization?organizationId=${id}`,
    );
  }

  /**
   * Get All Company Members ByCompany Id Method
   * @param id
   * @returns Observable Company Member Array
   */
  getAllCompanyMemberByCompanyId(id): Observable<CompanyMember[]> {
    console.log('getAllCompanyMemberByCompanyId');
    return this.httpClient.get<CompanyMember[]>(
      `${environment.API_CONF.BLINK_CORP_API}/getListOfCorporateMembers?organizationId=${id}`,
    );
  }

  /**
   * Get Organization MoSo ID Method
   * @param id
   * @returns Observable
   */
  getOrganizationMoSoID(id): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.API_CONF.BLINK_CORP_API}/getOrganizationMoSoID?cognitoOrganizationId=${id}`,
    );
  }

  /**
   * Get Organization Details By Id Method
   * @param id
   * @returns Observable of Company Data type
   */
  getOrganizationDetailsById(id): Observable<CompanyData> {
    return this.httpClient.get<CompanyData>(
      `${environment.API_CONF.BLINK_CORP_API}/getOrganizationDetails?organizationId=${id}`,
    );
  }

  /**
   * Post CreateAndFinalizeCorporateAgreement Method
   * @param data: CreateAgreement
   * @returns Observable of ApiResponse
   */
  createAndFinalizeCorporateAgreement(
    data: CreateAgreement,
  ): Observable<ApiResponse> {
    return this.httpClient.post<ApiResponse>(
      `${environment.API_CONF.BLINK_CORP_API}/createAndFinalizeCorporateAgreement`,
      data,
    );
  }

  /**
   * Get Describe Request Execution Method
   * @param executionId: string
   * @returns Observable of CreateAgreementResponse
   */
  describeRequestExecution(
    executionId: string,
  ): Observable<CreateAgreementResponse> {
    return this.httpClient
      .get<CreateAgreementResponse>(
        `${environment.API_CONF.BLINK_CORP_API}/describeRequestExecution?executionId=${executionId}`,
      )
      .pipe(retry(3), delay(1000));
  }

  /**
   * Get CorporatePrices Method
   * @param facilityId: string
   * @param primaryMemberAgreementId: string
   * @returns Observable of Prices
   */
  getCorporatePrices(
    facilityId: string,
    // primaryMemberAgreementId: number,
  ): Observable<IPrices> {
    console.log('facilityId --->', facilityId);
    return this.httpClient.get<IPrices>(
      `https://gb9eegn3g7.execute-api.us-east-1.amazonaws.com/qa/GetFacilityByID?facilityId=${facilityId}`, // TODO: add custom domain
      // `${environment.API_CONF.BLINK_CORP_API}/getCorporatePrices?facilityId=${facilityId}&primaryMemberAgreementId=${primaryMemberAgreementId}`,
    );
  }
}
